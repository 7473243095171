
import { CustomButton } from '../../../../components/HtmlComponents';

const LoanType = ({ loanType, setLoanType }) => {

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "center" , alignItems:"center", height:"100vh"}}>
                <div>
                    <h2>Select Loan Type:</h2>
                    <div>
                        <CustomButton
                            buttonOnClick={() => setLoanType('consumer')}
                            buttonText="Consumer/Personal"
                            buttonClassName="btn btn-primary"
                            isActive={true}
                            buttonStyle={{ marginRight: "10px" }}
                        />
                        <CustomButton
                            buttonOnClick={() => setLoanType('commercial')}
                            buttonText="Commercial/Business"
                            buttonClassName="btn btn-primary"
                            isActive={true}
                            buttonStyle={{ marginRight: "10px" }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

}

export default LoanType;