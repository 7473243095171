import { useEffect, useState } from "react";
import { DownArrowIcon } from '../../../../components/HtmlComponents/Icons';
import { Formik, Form, Field, FieldArray } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, EmailField, SelectDatePicker, Modal, CustomButton } from '../../../../components/HtmlComponents';
import Constants from "../../../../components/global/Constants";
import { capitalizeFirst, formatDate } from '../../../../helpers';
import ResidentialHistory from "../personalDetail/components/ResidentialHistory";
import Notifications from "../../../../components/global/Notifications";
 

import PersonalDetailValidationSchema from "./schema/PersonalDetailValidationSchema";

const PersonalDetail = ({ initialValues, formRef, executeApplicationSubmissionHandler, applicationId, applicantId, openByDefault, addBackButton, backHandler }) => {
    const [isOpen, setIsOpen] = useState(openByDefault === true ? "block" : "none");
    const [titleList, setTitleList] = useState([]);
    const [genderList, setGenderList] = useState([]);
    const [relationshipStatusList, setRelationshipStatusList] = useState([]);
    const [residencyStatusList, setResidencyshipStatusList] = useState([]);
    const [licenseTypeList, setLicenseTypeList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [selectedLicenseState, setSelectedLicenseState] = useState(null);
    const [residentialStatusList, setResidentialStatusList] = useState([]);

 

    const FetchTitleList = () => {
        var titleOptions = [];
        var titleOptions = Constants.TITLE.map(item => { return { value: item, label: capitalizeFirst(item) }; })
        setTitleList(titleOptions);
    }

    const FetchGenderList = () => {
        var genderOptions = [];
        Constants.GENDER.map(item => {
            genderOptions.push({ value: item, label: capitalizeFirst(item) });
        })
        setGenderList(genderOptions);
    }

    const FetchRelationshipStatusList = () => {
        var relationshipStatusOptions = [];
        Object.keys(Constants.RELATIONSHIP_STATUS).map(key => {
            relationshipStatusOptions.push({ value: key, label: Constants.RELATIONSHIP_STATUS[key] });
        })
        setRelationshipStatusList(relationshipStatusOptions);
    }

    const FetchResidencyStatus = () => {
        var residencyStatusOptions = [];
        Object.keys(Constants.RESIDENCY_STATUS).map(key => {
            residencyStatusOptions.push({ value: key, label: Constants.RESIDENCY_STATUS[key] });
        })
        setResidencyshipStatusList(residencyStatusOptions);
    }

    const FetchLicenseTypes = () => {
        var licenseTypeOptions = [];
        Object.keys(Constants.LICENSE_TYPES).map(key => {
            licenseTypeOptions.push({ value: key, label: Constants.LICENSE_TYPES[key] });
        })
        setLicenseTypeList(licenseTypeOptions);
    }

    const FetchStates = () => {
        var stateOptions = [];
        Object.keys(Constants.STATES).map(key => {
            stateOptions.push({ value: key, label: Constants.STATES[key] });
        })
        setStateList(stateOptions);
    }

    const FetchResidentialStauts = () => {
        var options = [];
        Object.keys(Constants.RESIDENTIAL_STATUS).map(key => {
            options.push({ value: key, label: Constants.RESIDENTIAL_STATUS[key] });
        })
        setResidentialStatusList(options);
    }

    //residentialHistoryList

    const handleChanges = (fieldName, value, selectedOption) => {
        switch (fieldName) {
        }
    }

    const formatInitialValuesDates = () => {
        if (initialValues !== undefined && initialValues.personalDetail !== undefined) {
            // console.log("LINE 86 ------------------ initialValues.personalDetail  > ", initialValues.personalDetail);
            initialValues.personalDetail.dob = Number.isInteger(initialValues.personalDetail.dob) ? formatDate(initialValues.personalDetail.dob, "unix", "YYYY-MM-DD") : initialValues.personalDetail.dob;
            //LICENSE
            if (initialValues.personalDetail.license !== undefined && initialValues.personalDetail.license.expiryDate !== undefined) {
                initialValues.personalDetail.license.expiryDate = Number.isInteger(initialValues.personalDetail.license.expiryDate) && initialValues.personalDetail.license.expiryDate !== 0 ? formatDate(initialValues.personalDetail.license.expiryDate, "unix", "YYYY-MM-DD") : initialValues.personalDetail.license.expiryDate;
            }
            //MEDICARE
            if (initialValues.personalDetail.medicare !== undefined && initialValues.personalDetail.medicare.expiryDate !== undefined) {
                initialValues.personalDetail.medicare.expiryDate = Number.isInteger(initialValues.personalDetail.medicare.expiryDate) && initialValues.personalDetail.medicare.expiryDate !== 0 ? formatDate(initialValues.personalDetail.medicare.expiryDate, "unix", "YYYY-MM-DD") : initialValues.personalDetail.medicare.expiryDate;
            }
        }

    }


    useEffect(() => {
        FetchTitleList();
        FetchGenderList();
        FetchRelationshipStatusList();
        FetchResidencyStatus();
        FetchLicenseTypes();
        FetchStates();
        FetchResidentialStauts();
    }, [])


    useEffect(() => {
        formatInitialValuesDates();
    }, [initialValues])

 

    const BasicInfo = ({ values, errors, touched, setFieldValue }) => {

        return (
            <>
                <div className="row mb-5">
                    <div className="col-sm-12">
                            
                    </div>
                    <div className="col-sm-2 col-md-2 mb-5 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Title</label>
                        <Field name="title">
                            {({ field }) => (
                                <SelectField
                                    fieldName="title"
                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                    options={titleList}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors && errors.title ? <div className="error"> {errors.title} </div> : "" } 
                    </div>

                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">First Name</label>
                        <Field name="firstName">
                            {({ field }) => (
                                <InputField fieldName="firstName" placeholder="First Name" fieldValue={field.value} setFieldValue={setFieldValue} capitalizeFirstLetter={true} />
                            )}
                        </Field>
                        {errors && errors.firstName ? <div className="error"> {errors.firstName} </div> : "" } 
                    </div>

                    <div className="col-sm-2 col-md-2 mb-5 fv-row fv-plugins-icon-container">
                        <label className=" fs-8 fw-bold mb-2">Middle Name</label>
                        <Field name="middleName">
                            {({ field }) => (
                                <InputField fieldName="middleName" placeholder="Middle Name" fieldValue={field.value} setFieldValue={setFieldValue} capitalizeFirstLetter={true} />
                            )}
                        </Field>
                        {errors && errors.middleName ? <div className="error"> {errors.middleName} </div> : "" } 
                    </div>

                    <div className="col-sm-2 col-md-2 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Last Name</label>
                        <Field name="surname">
                            {({ field }) => (
                                <InputField fieldName="surname" placeholder="Last Name" fieldValue={field.value} setFieldValue={setFieldValue} capitalizeFirstLetter={true} />
                            )}
                        </Field>
                        {errors && errors.surname ? <div className="error"> {errors.surname} </div> : "" } 
                    </div>

                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Preferred Name</label>
                        <Field name="preferredName">
                            {({ field }) => (
                                <InputField fieldName="preferredName" placeholder="Preferred Name" fieldValue={field.value} setFieldValue={setFieldValue} capitalizeFirstLetter={true} />
                            )}
                        </Field>
                        {errors && errors.preferredName ? <div className="error"> {errors.preferredName} </div> : "" } 
                    </div>

                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <div style={{ marginTop: "15px" }}>
                            <Field name="gender">
                                {({ field }) => (
                                    <RadioGroup
                                        fieldName="gender"
                                        defaultSelectedOption={field.value}
                                        options={genderList}
                                        setFieldValue={setFieldValue}
                                        layoutStyle="vertical"
                                    />
                                )}
                            </Field>
                        </div>
                    </div>
                    {errors && errors.gender ? <div className="error"> {errors.gender} </div> : "" }

                    <div className="col-sm-4 col-md-4 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Birth Date</label>

                        <Field name="dob">
                            {({ field }) => (
                                <SelectDatePicker
                                    fieldName="dob"
                                    fieldValue={Number.isInteger(field.value) && field.value !== 0 ? formatDate(field.value, "unix", "YYYY-MM-DD") : (field.value == 0 ? "0000-00-00" : field.value)}
                                    setFieldValue={setFieldValue}
                                    applicantId={applicantId}
                                    maxYear={2010}
                                />
                            )}
                        </Field>
                        {errors && errors.dob ? <div className="error"> {errors.dob} </div> : "" }

                    </div>

                    <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Relationship Status</label>
                        <Field name="relationshipStatus">
                            {({ field }) => (
                                <SelectField
                                    fieldName="relationshipStatus"
                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                    options={relationshipStatusList}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors && errors.relationshipStatus ? <div className="error"> {errors.relationshipStatus} </div> : "" }
                    </div>

                    <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Residency Status</label>
                        <Field name="residencyStatus">
                            {({ field }) => (
                                <SelectField
                                    fieldName="residencyStatus"
                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                    options={residencyStatusList}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors && errors.residencyStatus ? <div className="error"> {errors.residencyStatus} </div> : "" }
                    </div>

                    {/************************************** DEPENDANTS - DEPENDANTS AGE START HERE*******************************/}
                    <div className="col-sm-3 col-md-1 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Dependants</label>
                        <FieldArray name="dependantsAge">
                            {({ remove, push }) => (
                                <div>
                                    <Field name="totalDependants" className="form-control" style={{ height: "35px", fontWeight: "400" }}
                                        onChange={(e) => {
                                            setFieldValue("totalDependants", e.target.value);
                                            var tmpTotalDependants = e.target.value;
                                            var dependantsAgeArrayLength = values.dependantsAge.length;

                                            //If dependantsAge array length is greater than current total dependants then 
                                            // popup the dependant otherwise push a new ones
                                            if (tmpTotalDependants > dependantsAgeArrayLength) {
                                                var extraDependants = tmpTotalDependants - dependantsAgeArrayLength;
                                                for (var i = 0; i < extraDependants; i++) { push(0); }
                                            } else if (tmpTotalDependants < dependantsAgeArrayLength) {
                                                var extraDependants = dependantsAgeArrayLength - tmpTotalDependants;
                                                for (var i = 0; i < extraDependants; i++) { remove(0); }
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </FieldArray>
                        {errors && errors.totalDependants ? <div className="error"> {errors.totalDependants} </div> : "" }
                    </div>
                    <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                        <div className="row">
                            {values.dependantsAge.map((p, index) => {
                                return (
                                    <div className="col-sm-2" key={index}>
                                        <label className="  fs-8 fw-bold mb-2">Dependant {index + 1}</label>
                                        <Field name={`dependantsAge[${index}]`}>
                                            {({ field }) => (
                                                <InputField fieldName={`dependantsAge[${index}]`} placeholder="Dependant" fieldValue={field.value} setFieldValue={setFieldValue} />
                                            )}
                                        </Field>
                                    </div>
                                );
                            })}
                            {errors && errors.dependantsAge ? <div className="error"> {errors.dependantsAge} </div> : "" }
                        </div>
                    </div>
                    {/************************************** DEPENDANTS - DEPENDANTS AGE END HERE*******************************/}

                    <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Driver License #</label>
                        <Field name="license.number">
                            {({ field }) => (
                                <InputField fieldName="license.number" placeholder="License" fieldValue={field.value} setFieldValue={setFieldValue} charactersLimit={12} />
                            )}
                        </Field>
                        {errors && errors.license && errors.license.number ? <div className="error"> {errors.license.number} </div> : "" }
                    </div>

                    <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Type</label>
                        <Field name="license.type">
                            {({ field }) => (
                                <SelectField
                                    fieldName="license.type"
                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                    options={licenseTypeList}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors && errors.license && errors.license.type ? <div className="error"> {errors.license.type} </div> : "" }
                    </div>
                    <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">State</label>
                        <Field name="license.state">
                            {({ field }) => (
                                <SelectField
                                    fieldName="license.state"
                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                    options={stateList}
                                    setFieldValue={setFieldValue}
                                    callback={handleChanges}
                                />
                            )}
                        </Field>
                        {errors && errors.license && errors.license.state ? <div className="error"> {errors.license.state} </div> : "" }
                    </div>
                    <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Card #</label>
                        <Field name="license.cardNumber">
                            {({ field }) => (
                                <InputField fieldName="license.cardNumber" placeholder="Card #" fieldValue={field.value} setFieldValue={setFieldValue} />
                            )}
                        </Field>
                        {errors && errors.license && errors.license.cardNumber ? <div className="error"> {errors.license.cardNumber} </div> : "" }
                    </div>
                    <div className="col-sm-3 col-md-4 mb-3 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">License Expiry Date</label>
                        <Field name="license.expiryDate">
                            {({ field }) => (
                                // console.log("LINE 310 ------------------------  ", field.value  ),
                                <SelectDatePicker
                                    fieldName="license.expiryDate"
                                    fieldValue={Number.isInteger(field.value) && field.value !== 0 ? formatDate(field.value, "unix", "YYYY-MM-DD") : (field.value == 0 ? "0000-00-00" : field.value)}
                                    setFieldValue={setFieldValue}
                                    minYear={2024}
                                />
                            )}
                        </Field>
                        {errors && errors.license && errors.license.expiryDate ? <div className="error"> {errors.license.expiryDate} </div> : "" }
                    </div>

                    <div className="col-sm-3 col-md-12  fv-row fv-plugins-icon-container"></div>


                    <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container">
                        <label className="fs-8 fw-bold mb-2">Medicare #</label>
                        <Field name="medicare.number">
                            {({ field }) => (
                                <InputField fieldName="medicare.number" placeholder="Number" fieldValue={field.value} setFieldValue={setFieldValue} />
                            )}
                        </Field>
                        {errors && errors.medicare && errors.medicare.number ? <div className="error"> {errors.medicare.number} </div> : "" }
                    </div>

                    <div className="col-sm-3 col-md-3 mb-3 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Medicare Expiry Date</label>
                        <Field name="medicare.expiryDate">
                            {({ field }) => (
                                <SelectDatePicker
                                    fieldName="medicare.expiryDate"
                                    fieldValue={Number.isInteger(field.value) && field.value !== 0 ? formatDate(field.value, "unix", "YYYY-MM-DD") : (field.value == 0 ? "0000-00-00" : field.value)}
                                    setFieldValue={setFieldValue}
                                    minYear={2024}
                                    displayDayField={0}
                                    displayMonthField={1}
                                    displayYearField={1}
                                />
                            )}
                        </Field>
                    </div>
                    <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container">
                        <label className="fs-8 fw-bold mb-2">Ref #</label>
                        <Field name="medicare.ref">
                            {({ field }) => (
                                <InputField fieldName="medicare.ref" placeholder="Ref" fieldValue={field.value} setFieldValue={setFieldValue} />
                            )}
                        </Field>
                    </div>
                </div>
            </>
        )
    }



    const ContactDetails = ({ errors, touched, setFieldValue }) => {


        return (
            <>
                <div className="row mb-5">
                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Mobile #</label>
                        <Field name="mobileNumber">
                            {({ field }) => (
                                <InputField
                                    fieldName="mobileNumber"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors && errors.mobileNumber   ? <div className="error"> {errors.mobileNumber} </div> : "" }
                    </div>

                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Home #</label>
                        <Field name="homeNumber">
                            {({ field }) => (
                                <InputField
                                    fieldName="homeNumber"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors && errors.homeNumber   ? <div className="error"> {errors.homeNumber} </div> : "" }
                    </div>

                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Employer #</label>
                        <Field name="employerNumber">
                            {({ field }) => (
                                <InputField
                                    fieldName="employerNumber"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors && errors.employerNumber   ? <div className="error"> {errors.employerNumber} </div> : "" }
                    </div>

                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Email</label>
                        <Field name="email">
                            {({ field }) => (
                                <EmailField
                                    fieldName="email"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors && errors.email   ? <div className="error"> {errors.email} </div> : "" }
                    </div>


                </div>
            </>
        )
    }

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="personalinfoBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <h3 className="pb-0 mb-0">Personal Details</h3>
                    </div>

                    { /** personal infort=mation block start  */}
                    <div>
                        <div className="separator separator-dashed my-5"></div>

                        <Formik
                            validationSchema={PersonalDetailValidationSchema}
                            initialValues={initialValues.personalDetail}
                            innerRef={formRef}
                            enableReinitialize={true}
                            //validateOnBlur={false}
                            validateOnChange={true}
                            validateOnBlur={true}
                            onSubmit={values => {
                                executeApplicationSubmissionHandler("personalDetail");
                            }}
                        >
                            {({ errors, touched, setFieldValue, values }) => (
                                <Form>                                     
                                    <BasicInfo values={values} errors={errors} touched={touched} setFieldValue={setFieldValue} />
                                    <ResidentialHistory
                                        errors={errors}
                                        touched={touched}
                                        setFieldValue={setFieldValue}
                                        residentialStatusList={residentialStatusList}
                                        residentialHistory={initialValues.personalDetail.residentialHistory}
                                        values={values}

                                    />
                                    <ContactDetails errors={errors} touched={touched} setFieldValue={setFieldValue} />
                                    
                                    <div className="col-xl-12"> 
                                        <div style={{display:"flex", flexDirection:"row-reverse"}}>
                                            {Object.keys(errors).length > 0 ?
                                                <FormSubmissionButton isActive={false} label="Save & Continue" /> :
                                                <FormSubmissionButton isActive={true} label="Save & Continue" />
                                            }
                                            
                                            {
                                                addBackButton === true ? 
                                                <CustomButton 
                                                buttonClassName="btn btn-sm btn-light btn-active-secondary me-5" 
                                                isActive={true} 
                                                buttonOnClick = {() => {
                                                    backHandler();
                                                }}
                                                buttonText="Go Back" 
                                                
                                                />: ""
                                            }

                                        </div>
                                    </div>
                                </Form>  

                                

                            )}

                        </Formik>
                    </div>

                </div>
            </div>
        </>
    )
}

export default PersonalDetail;