import { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { Quote, PersonalDetail, Income, Assets, Debts, Expenses } from '../consumerApplication/components';
import LoanType from './components/Quote/LoanType';

import ConsumerApplicationSchema from '../consumerApplication/schema/ConsumerApplicationSchema.json';
import { RightDrawer, SelectField, CustomButton } from '../../components/HtmlComponents';
import { useToken } from '../../hooks/useAuth';
import { createConsumerApplication, updateApplication, getApplication, createApplicant, updateApplicant, getAccessTokenPermissions } from "../../api";
import DecideErrorPage from "../../components/HtmlComponents/errors/DecideErrorPage";
import Notifications from '../../components/global/Notifications';
import LoadingScreen from '../../components/global/LoadingScreen';
import { useNavigate } from 'react-router-dom'
import { capitalizeFirst, formatDate, calculateMonthlyAmount } from "../../helpers";
import ApplicationSubmissionPage from './components/ApplicationSubmissionPage';
import CongratulationMessageOnSubmission from './components/CongratulationMessageOnSubmission';

import { deleteCookie } from '../../utils/Cookies';

const ConsumerApplication = () => {
    const quoteRef = useRef();
    const personalDetailsRef = useRef();
    const incomeRef = useRef();
    const assetsRef = useRef();
    const debtsRef = useRef();
    const expensesRef = useRef();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState("personalDetail");  //quote | personalInformation | income | assets  | debts | expenses
    const [application, setApplication] = useState(ConsumerApplicationSchema);
    const [applicationId, setApplicationId] = useState(0);
    const [applicants, setApplicants] = useState([]);       //list of all applicants against this application     
    const [applicant, setApplicant] = useState(ConsumerApplicationSchema["applicants"][0]); // ALWAYS LOAD FIRST APPLICANT ON APPLICATION LOADING - ConsumerApplicationSchema GOT AN ARRAY OF APPLICANTS
    const [applicantId, setApplicantId] = useState(0);
    const [loanType, setLoanType] = useState("");

    const [openTab, setOpenTab] = useState("personalDetail");  // BY DEFAULT QUOTE TAB SHOULD OPEN,  //quote
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: {} });
    const [processing, setProcessing] = useState(true);
    const [serverStatusCode, setServerStatusCode] = useState(null);
    const [applicationErrors, setApplicationErrors] = useState({});  //APPLICATION ERRORS

    //PERMISSIONS
    const [updateApplicationPermission, setUpdateApplicationPermission] = useState(false);
    const [uploadFilesPermission, setUploadFilesPermission] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [token, setToken] = useState("");

    const params = new URLSearchParams(window.location.search);
    const base64Token = params.get('token');

    const handleNavigateion = (page, payload) => {
        switch (page) {
            /*case "":
                break;*/
            case "uploadFile":
                navigate("/files?token=" + payload["base64Token"]);
                break;

        }
    }

    //IF 
    useEffect(() => {
        //CHECK IF TOKEN IS PROVIDED IN THE URL THEN SET THE APPLICATION AND APPLICANT ID AND THEN FETCH THE APPLICATION
        const decodeToken = async () => {
            try {
                if (base64Token !== null && base64Token !== undefined && base64Token !== "" && base64Token) {
                    let decodedBase64Token = JSON.parse(atob(base64Token));
                    let tmpToken = decodedBase64Token.token;
                    let tmpApplicationId = decodedBase64Token.applicationId || 0;
                    let tmpApplicantId = decodedBase64Token.applicantId || 0;

                    setToken(decodedBase64Token.token);
                    setApplicationId(decodedBase64Token.applicationId || 0);
                    setApplicantId(decodedBase64Token.applicantId || 0);

                    //RETREIEVE THE PERMISSION FROM BACKEND
                    try{
                        await getAccessTokenPermissions(tmpToken, tmpApplicationId, tmpApplicantId).then((response) => {
                            var permissions = response["data"]["permissions"] || { updateApplicationPermission: 0, uploadFilesPermission: 0 };
                            setUpdateApplicationPermission(permissions["updateApplicationPermission"]);
                            setUploadFilesPermission(permissions["uploadFilesPermission"]);
    
                            if (permissions["updateApplicationPermission"] === 1) {
                                fetchApplication(decodedBase64Token.applicationId, decodedBase64Token.applicantId, decodedBase64Token.token);
                            }
                            if (permissions["updateApplicationPermission"] === 0 && permissions["uploadFilesPermission"] === 1) {
                                handleNavigateion("uploadFile", { base64Token: base64Token });
                            }
                        });
                    }catch(e){ Notifications("error", e.message || "Error - Please contact NFAL team"); }

                    setServerStatusCode(200);
                    setProcessing(false);
                }
                else {
                    setApplicationId(0);
                    setApplication(ConsumerApplicationSchema);

                    setApplicants([]);
                    setApplicant(ConsumerApplicationSchema["applicants"][0]);
                    setApplicantId(0);
                    setActiveTab("quote");

                    setServerStatusCode(200);
                    setProcessing(false);
                }
            }
            catch (e) { }
        }
        decodeToken();


        //READ THE APPLICATION FROM SESSION
        const applicationObj = JSON.parse(sessionStorage.getItem("application"));
    }, []);




    //PARSE THE DATA AND UPDATE THE APPLICATION STATE
    function parseAndUpdateApplicationState(applicationObj) {
        //PARSE JSON STRINGS
        applicationObj.loan = JSON.parse(applicationObj.loan);
        applicationObj.financial = JSON.parse(applicationObj.financial);
        applicationObj.buyingItem = JSON.parse(applicationObj.buyingItem);
        applicationObj.repayments = JSON.parse(applicationObj.repayments);

        // applicationObj.personalDetail = JSON.parse(applicationObj.personalDetail);
        // applicationObj.income = JSON.parse(applicationObj.income);
        // applicationObj.assets = JSON.parse(applicationObj.assets);
        // applicationObj.debts = JSON.parse(applicationObj.debts);
        // applicationObj.expenses = JSON.parse(applicationObj.expenses);

        setApplication(applicationObj);

    }

    //SUBMIT APPLICATION TO SERVER
    const submitApplicationToServer = async () => {
        setProcessing(true);
        try {
            let tmpApplication = JSON.parse(JSON.stringify(application));
            delete tmpApplication["applicants"];
            delete tmpApplication.applicationNotes;

            let applicationPayload = {
                application: JSON.parse(JSON.stringify(tmpApplication)),
                applicant: JSON.parse(JSON.stringify(applicant))
            }
            var response = await createConsumerApplication(token, applicationId, applicantId, applicationPayload);

            if (response["error"] !== undefined && response["error"] == 1) {
                setErrorMessage(response["message"] || "Error");
                Notifications("error", response["message"] || "Error");
                setProcessing(false);
            }
            else {
                Notifications("success", "Application submitted successfully.");
                deleteCookie("noLiabilities");
                setProcessing(false);

                //IF THIS IS A NEW APPLICATION THEN RETRIEVE THE TOKEN FROM THE RESPONSE AND REDIRECT THE USER TO THE FILES PAGE
                if (applicationId == 0 && response["data"] !== undefined && response["data"]["token"] !== undefined) {
                    let token = response["data"]["token"];
                    handleNavigateion("uploadFile", { base64Token: token }); //REDIRECT TO FILES PAGE
                }

                //CHECK IF UPLOAD FILES PERMISSION IS ALLOWED THEN REDIRECT TO FILES PAGE
                else if (uploadFilesPermission) {
                    handleNavigateion("uploadFile", { base64Token: base64Token }); //REDIRECT TO FILES PAGE
                }
                else {
                    //tabHandler
                    tabHandler("CongratulationMessageOnSubmission");
                }
            }
            //if (response["error"] !== undefined) { fetchApplication(); }
            //else { Notifications("error", "Unable to create a applicant."); }
        }
        catch (e) {
            //console.log("Exception :", e)
        }
        // setProcessing(false);

    }

    //FETCHING APPLICATION FROM SERVER IF APPLICATION ID IS PROVIDED IN THE URL
    const fetchApplication = async (applicationId, applicantId, token) => {
        const response = await getApplication(applicationId, applicantId, token);
        if (response["httpCode"]) {
            setServerStatusCode(response["httpCode"]);
            if (response["httpCode"] == 200) {
                console.log("response ------------------------------------------  :: ", response);


                //CHECK IF IT HAS AN APPLICANTS ARRAY AND IT CONTAINS APPLICANTS THEN ASSIGN FIRST APPLICANT TO APPLIANT STATE
                if (response.data.applicants !== undefined && response.data.applicants[0] !== undefined) {
                    //setApplicants(response.data.applicants);

                    let tmpApplicant = response.data.applicants[0];
                    /*await new Promise( async(resolve, reject) => {

                        //UPDATE THE ASSETS 
                        var localAssets = await tmpApplicant.assets.map((asset) => {
                            asset["uId"] = "77777"; //uuid();
                            return asset;
                        })
                        resolve(localAssets);
                        //tmpApplicant.assets = localAssets;

                    }).then((localAssets) => {
                        console.log("assets updated ------------------ ", localAssets);
                        tmpApplicant.assets = localAssets;
                        setApplicant(tmpApplicant);
                    });*/


                    setApplicant(tmpApplicant);


                }
                var quoteData = response.data;
                delete quoteData.applicants;

                setApplication(response.data);
                setProcessing(false);
            }
            else {
                Notifications("error", response.message || "Error")
                setProcessing(false);
            }
        }
    }

    //UPDATE APPLICANTION AND APPLICANT PAYLOAD WHEN USER CLICKS SAVE AND ALSO UPDATE THE TAB- MOVE TO NEXT TAB
    const updatePayload = (moduleName, values = {}) => {
        let tmpValues = {};

        switch (moduleName) {
            case "quote":
                setApplication((prevValues) => {
                    return {
                        ...prevValues,
                        category: values["category"],
                        insurances: [],
                        loan: values["loan"],
                        financial: values["financial"],
                        buyingItem: values["buyingItem"],
                    }
                });
                //STORE APPLICATION IN THE SESSION STORAGE
                //sessionStorage.setItem("application", JSON.stringify(application));
                break;
            case "personalDetail":
                tmpValues = personalDetailsRef.current.values;
                //CHECK IF RESIDENTIAL HISTORY PROVIDED, CURRENT RENT PROVIDED AND FREQUENCY IS NOT MONTHLY THEN CONVERT IT INTO MONTHLY
                try {
                    if (tmpValues["residentialHistory"] !== undefined && tmpValues["residentialHistory"]["current"] !== undefined && tmpValues["residentialHistory"]["current"]["rent"] !== undefined && !isNaN(parseFloat(tmpValues["residentialHistory"]["current"]["rent"]["applicantShareOfMonthlyRent"])) && tmpValues["residentialHistory"]["current"]["rent"]["frequency"] !== undefined && tmpValues["residentialHistory"]["current"]["rent"]["frequency"] !== "monthly") {
                        tmpValues["residentialHistory"]["current"]["rent"]["applicantShareOfMonthlyRent"] = calculateMonthlyAmount(tmpValues["residentialHistory"]["current"]["rent"]["frequency"], tmpValues["residentialHistory"]["current"]["rent"]["applicantShareOfMonthlyRent"]);
                        tmpValues["residentialHistory"]["current"]["rent"]["frequency"] = "monthly";
                    }
                }
                catch (e) { }
                //values = { "personalDetail": tmpValues };
                setApplicant({ ...applicant, [moduleName]: tmpValues });
                break;
            case "income":
                tmpValues = incomeRef.current.values;
                if (tmpValues["income"] !== undefined) {
                    //PRIMARY INCOME
                    var primaryIncomeArray = tmpValues["income"]["primaryIncome"];
                    if (primaryIncomeArray !== undefined && Array.isArray(primaryIncomeArray) && primaryIncomeArray.length > 0) {
                        var primaryIncome = primaryIncomeArray[0];
                        if (primaryIncome["netIncome"] !== undefined && !isNaN(parseFloat(primaryIncome["netIncome"])) && primaryIncome["frequencey"] !== undefined && primaryIncome["frequencey"] !== "monthly") {
                            primaryIncome["netIncome"] = calculateMonthlyAmount(primaryIncome["frequencey"], primaryIncome["netIncome"]);
                            primaryIncome["grossIncome"] = calculateMonthlyAmount(primaryIncome["frequencey"], primaryIncome["grossIncome"]);
                            primaryIncome["frequencey"] = "monthly";
                        }

                        primaryIncomeArray[0] = primaryIncome;
                        tmpValues["income"]["primaryIncome"] = primaryIncomeArray;
                    }

                    //SECONDARY INCOME
                    var secondaryIncomeArray = tmpValues["income"]["secondaryIncome"];
                    if (Array.isArray(secondaryIncomeArray) && secondaryIncomeArray.length > 0) {
                        var tmpSecondaryIncome = secondaryIncomeArray.map((income) => {
                            var tmpIncome = { ...income };
                            if (tmpIncome["netIncome"] !== undefined && !isNaN(parseFloat(tmpIncome["netIncome"])) && tmpIncome["frequencey"] !== undefined && tmpIncome["frequencey"] !== "monthly") {
                                tmpIncome["netIncome"] = calculateMonthlyAmount(tmpIncome["frequencey"], tmpIncome["netIncome"]);
                                tmpIncome["grossIncome"] = calculateMonthlyAmount(tmpIncome["frequencey"], tmpIncome["grossIncome"]);
                                tmpIncome["frequencey"] = "monthly";
                            }
                            return tmpIncome;
                        });
                        tmpValues["income"]["secondaryIncome"] = tmpSecondaryIncome;
                    }

                    //PREVIOUS INCOME
                    var previousIncomeArray = tmpValues["income"]["previousIncome"];
                    if (Array.isArray(previousIncomeArray) && previousIncomeArray.length > 0) {
                        var tmpPreviousIncome = previousIncomeArray.map((income) => {
                            var tmpIncome = { ...income };
                            if (tmpIncome["netIncome"] !== undefined && !isNaN(parseFloat(tmpIncome["netIncome"])) && tmpIncome["frequencey"] !== undefined && tmpIncome["frequencey"] !== "monthly") {
                                tmpIncome["netIncome"] = calculateMonthlyAmount(tmpIncome["frequencey"], tmpIncome["netIncome"]);
                                tmpIncome["grossIncome"] = calculateMonthlyAmount(tmpIncome["frequencey"], tmpIncome["grossIncome"]);
                                tmpIncome["frequencey"] = "monthly";
                            }
                            return tmpIncome;
                        });
                        tmpValues["income"]["previousIncome"] = tmpPreviousIncome;
                    }
                }
                setApplicant({ ...applicant, [moduleName]: tmpValues["income"] });
                //values = tmpValues; //incomeRef.current.values;
                break;
            case "assets":
                tmpValues = assetsRef.current.values;
                setApplicant({ ...applicant, [moduleName]: tmpValues["assets"] });
                break;
            case "debts":
                tmpValues = debtsRef.current.values;
                if (tmpValues["debts"] !== undefined && Array.isArray(tmpValues["debts"]) && tmpValues["debts"].length > 0) {
                    var tmpDebts = tmpValues["debts"].map((debt) => {
                        var tmpDebt = { ...debt };
                        if (tmpDebt["repaymentAmount"] !== undefined && !isNaN(parseFloat(tmpDebt["repaymentAmount"]))
                            && tmpDebt["repaymentFrequency"] !== undefined && tmpDebt["repaymentFrequency"] !== "monthly") {
                            tmpDebt["repaymentAmount"] = calculateMonthlyAmount(tmpDebt["repaymentFrequency"], tmpDebt["repaymentAmount"]);
                            tmpDebt["repaymentFrequency"] = "monthly";
                        }
                        return tmpDebt;
                    });
                    tmpValues["debts"] = tmpDebts;
                }
                //values = tmpValues; //debtsRef.current.values;
                setApplicant({ ...applicant, [moduleName]: tmpValues["debts"] });
                break;
            case "expenses":
                tmpValues = expensesRef.current.values;
                //UPDATE THE VALUES AND MAKE SURE THAT ALL VALUES ARE FOR MONTHLY IF NOT THEN CONVERT THEM INTO MONTHLY
                if (tmpValues["expenses"] !== undefined && Array.isArray(tmpValues["expenses"]) && tmpValues["expenses"].length > 0) {
                    var tmpExpenses = tmpValues["expenses"].map((expense) => {
                        var tmpExpense = { ...expense };
                        if (tmpExpense["frequency"] !== undefined && tmpExpense["frequency"] !== "monthly") {
                            tmpExpense["amount"] = calculateMonthlyAmount(tmpExpense["frequency"], tmpExpense["amount"]);
                            tmpExpense["frequency"] = "monthly";
                        }
                        return tmpExpense;
                    });
                    tmpValues["expenses"] = tmpExpenses;
                }
                //values = tmpValues;
                setApplicant({ ...applicant, [moduleName]: tmpValues["expenses"] });
                break;

        }

        const tabs = ["quote", "personalDetail", "income", "assets", "debts", "expenses", "ApplicationSubmissionPage"];   //tab name and modules names are same
        var nextIndex = 1;

        //CHECK IF LOAN TYPE IS COMMERCIAL AND THE CURRENT ACTIVE TBA IS DEBTS THEN MOVE TO APPLICATION SUBMISSION PAGE OTHER WISE DEFAULT BEHAVIOR LIKE GO TO NEXT TAB
        if (loanType === "commercial" && moduleName === "debts") {  nextIndex = tabs.indexOf("ApplicationSubmissionPage");}
        else{ nextIndex = tabs.indexOf(moduleName) + 1; }



        //CHECK IF NEXT TAB IS APPLICATION SUBMISSION PAGE THEN GET THE ERRORS FOR EACH MODUEL IF EXISTS, 
        if (tabs[nextIndex] === "ApplicationSubmissionPage") {
            var errors = { "quote": [], "personalDetail": [], "income": [], "assets": [], "debts": [], "expenses": [], "ApplicationSubmissionPage": [] };
            //QUOTE ERRORS
            let quoteErrors = quoteRef.current.errors;

            //PERSONAL DETAIL ERRORS
            let personalDetailErrors = personalDetailsRef.current.errors;

            //INCOME ERRORS
            let incomeErrors = incomeRef.current.errors;

            //ASSETS ERRORS
            let assetsErrors = assetsRef.current.errors;

            //DEBTS ERRORS
            let debtsErrors = debtsRef.current.errors;

            //EXPENSES ERRORS
            let expensesErrors = expensesRef.current.errors;

            setApplicationErrors({
                "quote": quoteErrors,
                "personalDetail": personalDetailErrors,
                "income": incomeErrors,
                "assets": assetsErrors,
                "debts": debtsErrors,
                "expenses": expensesErrors,
            })

        }
        tabHandler(tabs[nextIndex]);  //call tab handler tpo update the tab state so tab can be switched itsled 
    }

    useEffect(() => {
    }, [processing])

    useEffect(() => {
        application["loan"]["type"] = loanType;
    }, [loanType]);

    //TAB HANDLER
    const tabHandler = (tabName) => {
        setActiveTab(tabName);
    }



    //HANDLER TO DEAL WITH ACTIONS TRIGGERED BY APPLICATION SUBMISSIN PAGE
    const handler = (action, paylaod) => {
        switch (action) {
            case "goBackToQuote":
                if(token.length !== 0){ tabHandler("personalDetail");}
                else{ tabHandler("quote");}
                break;
            case "submitApplication":
                submitApplicationToServer();
                break;            
        }
    }

    return (
        <>
            <div className="container-xxl">
                {processing ? <LoadingScreen /> : (serverStatusCode != 200 ? <DecideErrorPage errorCode={serverStatusCode} message={errorMessage} /> :
                    <>

                        {activeTab === "ApplicationSubmissionPage" ? <ApplicationSubmissionPage callback={handler} applicationErrors={applicationErrors} /> : <></>}
                        {activeTab === "CongratulationMessageOnSubmission" ? <CongratulationMessageOnSubmission callback={handler} applicationErrors={applicationErrors} /> : <></>}



                        {/**activeTab === "ApplicationSubmissionPage" ? <ApplicationSubmissionPage callback={handler} applicationErrors={applicationErrors} /> */}                        
                        {
                            loanType === "" ? <LoanType loanType={loanType} setLoanType={setLoanType} /> 
                            : ["ApplicationSubmissionPage", "CongratulationMessageOnSubmission"].includes(activeTab) ? <></> :
                            <>
                                <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", marginTop: "20px", marginBottom: "20px" }}>
                                    {token.length === 0 ? <div className={activeTab === "quote" ? "tab activeTab" : "tab inactiveTab"} onClick={() => { /*tabHandler("quote")*/ }}><span>Quote</span></div> : ""}
                                    <div className={activeTab === "personalDetail" ? "tab activeTab" : "tab inactiveTab"} onClick={() => { /*tabHandler("personalDetail")*/ }}>Personal Informaiton</div>
                                    <div className={activeTab === "income" ? "tab activeTab" : "tab inactiveTab"} onClick={() => { /*tabHandler("income")*/ }}>Income</div>
                                    <div className={activeTab === "assets" ? "tab activeTab" : "tab inactiveTab"} onClick={() => { /*tabHandler("assets")*/ }}>Assets</div>
                                    <div className={activeTab === "debts" ? "tab activeTab" : "tab inactiveTab"} onClick={() => { /*tabHandler("debts")*/ }}>Debts</div>
                                    {
                                        loanType === "consumer" ? 
                                        <div className={activeTab === "expenses" ? "tab activeTab" : "tab inactiveTab"} onClick={() => { /*tabHandler("expenses")*/ }}>Expenses</div>
                                        : ""
                                    }
                                </div>

                                <div style={{ display: activeTab === "quote" ? "block" : "none" }}>
                                    <Quote
                                        initialValues={application}
                                        formRef={quoteRef}
                                        executeApplicationSubmissionHandler={updatePayload}
                                        applicationId={applicationId}
                                        openByDefault={openTab == "quote" ? true : false}
                                    />
                                </div>
                                <div style={{ display: activeTab === "personalDetail" ? "block" : "none" }}>
                                    <PersonalDetail 
                                        initialValues={applicant} 
                                        formRef={personalDetailsRef} 
                                        executeApplicationSubmissionHandler={updatePayload} 
                                        applicationId={applicationId} 
                                        applicantId={applicantId} 
                                        openByDefault={openTab == "personalDetail" ? true : false} 
                                        addBackButton={token.length === 0 ? true : false} 
                                        backHandler={() => { tabHandler("quote") }}
                                    />
                                </div>
                                <div style={{ display: activeTab === "income" ? "block" : "none" }}>
                                    <Income 
                                        initialValues={applicant} 
                                        formRef={incomeRef} 
                                        executeApplicationSubmissionHandler={updatePayload} 
                                        applicationId={applicationId} 
                                        applicantId={applicantId} 
                                        openByDefault={openTab == "income" ? true : false} 
                                        addBackButton={true}
                                        backHandler={() => { tabHandler("personalDetail") }}
                                    />
                                </div>
                                <div style={{ display: activeTab === "assets" ? "block" : "none" }}>
                                    <Assets 
                                        initialValues={applicant} 
                                        formRef={assetsRef} 
                                        executeApplicationSubmissionHandler={updatePayload} 
                                        applicationId={applicationId} 
                                        applicantId={applicantId} 
                                        openByDefault={openTab == "assets" ? true : false} 
                                        addBackButton={true}
                                        backHandler={() => { tabHandler("income") }}
                                    />
                                </div>
                                <div style={{ display: activeTab === "debts" ? "block" : "none" }}>
                                    <Debts 
                                        initialValues={applicant} 
                                        formRef={debtsRef} 
                                        executeApplicationSubmissionHandler={updatePayload} 
                                        applicationId={applicationId} 
                                        applicantId={applicantId} 
                                        openByDefault={openTab == "debts" ? true : false} 
                                        addBackButton={true}
                                        backHandler={() => { tabHandler("assets") }}
                                    />
                                </div>
                                <div style={{ display: activeTab === "expenses" ? "block" : "none" }}>
                                    <Expenses
                                        initialValues={applicant} 
                                        formRef={expensesRef} 
                                        executeApplicationSubmissionHandler={updatePayload} 
                                        applicationId={applicationId} 
                                        applicantId={applicantId} 
                                        openByDefault={openTab == "expenses" ? true : false} 
                                        addBackButton={true}
                                        backHandler={() => { tabHandler("debts") }}
                                    />
                                </div>
                            </>
                        }


                    </>
                )
                }
            </div>
        </>
    )
}

export default ConsumerApplication;



//BETTER APPROACH TO ADD , REMOVE ITEM FROM ARRAY
//https://codesandbox.io/s/react-formik-async-l2cc5?file=/index.js:2721-2726
//https://stackblitz.com/edit/react-formik-fieldarray-5kx4ob?file=src%2FApp.js    FORMIK ARRAY FIELD APPROACH -
//https://stackoverflow.com/questions/64674065/how-to-use-formik-with-dynamicly-generated-lists-e-g-via-ajax-or-setstate

